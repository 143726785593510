@media print {
  @page {
    size: A4;
    border: 0;
    padding: 0;
    margin: 0;
  }

  .no-print {
    display: none !important;
  }
  [data-cookiefirst-widget="banner"] {
    display: none !important;
    opacity: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
  }
}

body {
  margin: 0;
}
