.masthead {
  position: relative;
  /* background: url("media/the-interview.webp") no-repeat center center;*/
  background-size: cover;
  padding-top: 4rem; /* Reduced from 8rem for mobile */
  padding-bottom: 4rem; /* Reduced from 8rem for mobile */
}

.masthead h1 {
  font-size: 2rem; /* Reduced from 3rem for mobile */
}

.masthead p {
  font-size: 1rem; /* Adjust if necessary */
}

.masthead .btn-white {
  font-size: 1.5rem; /* Adjust if necessary */
  padding: 0.5rem 1.5rem; /* Adjust if necessary */
}

@media (min-width: 768px) {
  .masthead {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .masthead h1 {
    font-size: 4rem;
  }

  .masthead p {
    font-size: 1.25rem; /* Adjust if necessary for large screens */
  }

  .masthead .btn {
    font-size: 2rem;
    padding: 1rem 2rem; /* More padding for larger buttons on larger screens */
  }
}

.container-fluid,
.row {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100%; /* Full width on smaller screens */
  padding-top: 100%; /* Maintain aspect ratio for 1:1 videos */
  margin: 0 auto;
  height: 0;
}

@media screen and (min-width: 768px) {
  .video-container {
    position: relative;
    overflow: hidden;
    width: 50%; /* Reduced width on larger screens */
    padding-top: 50%; /* Adjust this value to maintain the desired aspect ratio based on your new width */
  }
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will cover the area of the video container without changing the aspect ratio */
  border: none; /* Removes any border around the video */
}

.video-demo-section {
  /* 20rem is 20 times the font-size of the root element */
  margin-top: 12rem; /* Adjust based on your design */
  position: relative;
  z-index: 100; /* Ensure the video is on top of other content */
}
